import GatsbyImage from "gatsby-image"
import { graphql, Link } from "gatsby"
import * as React from "react"
import { QuizIntroQuery } from "../types/api"
import { SecondaryHeader } from "../components/header"
import { SEO } from "../components/seo"
import { Footer } from "../components/Footer"
import { NonStretchedImage } from "../components/NonStretchedImage"
import * as TriviaStorage from "../model/TriviaStorage"
import { clearSnappy } from "../model/helpers"

const overlay = require("../images/overlay-2.jpg")
const chevronLeft = require("../images/chevron-left.png")
const chevronRight = require("../images/chevron-right.png")
const play = require("../images/play.png")

type Props = {
  data: QuizIntroQuery
  location: any
}

export default class extends React.Component<Props> {
  componentDidMount() {
    clearSnappy()
  }

  render() {
    TriviaStorage.saveUtmFromLocation(this.props.location)
    const {
      data: {
        featuredImage,
        quizzesJson: { node: quiz },
      },
    } = this.props

    let metaDescription = "Quiz: " + quiz.title
    if (quiz.description) {
      metaDescription = quiz.description.substring(0, 290) + " ..."
    }

    let metaImg = null
    if (featuredImage && featuredImage.childImageSharp) {
      metaImg = featuredImage.childImageSharp.fluid
    }

    return (
      <>
        <SecondaryHeader />
        <SEO title={`Quiz: ${quiz.title}`} isQuiz={true} description={metaDescription} url={quiz.slug} img={metaImg} />
        <div className="answer-modal hide-it" id="answer-modal" />
        <div id="page-level-tests" />
        <div className="game-parent">
          <div className="container">
            <div className="row">{this.renderContent()}</div>
          </div>
        </div>
        <Footer />
      </>
    )
  }

  renderContent() {
    const {
      data: {
        featuredImage,
        quizzesJson: { node: quiz },
      },
    } = this.props
    return (
      <div className="container">
        <div className="row">
          <div
            className="col-md-8 col-md-offset-2"
            style={{
              backgroundColor: "#fff",
              boxShadow: "0 0px 15px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div className="row pad-bottom-30">
              <div className="col-md-12 pad-top-15">
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="game-title" dangerouslySetInnerHTML={{ __html: quiz.title }} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 author-info-small">
                    By: {quiz.author.firstName} {quiz.author.lastName}
                  </div>
                  {quiz.publishAt && (
                    <div className="col-md-12 published-info-small">
                      Published:{" "}
                      {new Date(quiz.publishAt).toLocaleDateString("en-En", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {this.nextQuestionImage && (
              <div style={{ width: 0, height: 0, overflow: "hidden !important", visibility: "hidden" }}>
                <NonStretchedImage fluid={this.nextQuestionImage} maxHeight={400} loading="eager" />
              </div>
            )}
            {
              <div style={{ width: 0, height: 0, overflow: "hidden !important", visibility: "hidden" }}>
                <img src={overlay} alt="overlay" />
              </div>
            }
            <div className="row">
              <div className="col-md-12 start-quiz-button-wrap">
                <div
                  className="chevron"
                  style={{ backgroundImage: "url(" + chevronRight + ")", backgroundPosition: "left" }}
                ></div>
                <a
                  href={process.env.GATSBY_URL_PREFIX + `/${quiz.slug}/page/2`}
                  id="start-quiz"
                  className="btn btn-primary start-quiz-button"
                >
                  <div className="start-quiz-button-play">
                    <div style={{ backgroundImage: "url(" + play + ")" }}></div>
                  </div>
                  <span>Start Quiz</span>
                </a>
                <div
                  className="chevron"
                  style={{ backgroundImage: "url(" + chevronLeft + ")", backgroundPosition: "right" }}
                ></div>
              </div>
            </div>
            {featuredImage ? (
              <div className="row">
                <div className="col-md-12">
                  <a href={process.env.GATSBY_URL_PREFIX + `/${quiz.slug}/page/2`}>
                    <GatsbyImage
                      fluid={featuredImage.childImageSharp.fluid}
                      className="intro-featured-image"
                      loading="eager"
                      fadeIn={false}
                    />
                  </a>
                  <span style={{ fontSize: "12px " }}>Getty Images</span>
                </div>
              </div>
            ) : null}
            <div className="row pad-bottom-30">
              <div className="col-md-12 start-quiz-button-wrap">
                <div
                  className="chevron"
                  style={{ backgroundImage: "url(" + chevronRight + ")", backgroundPosition: "left" }}
                ></div>
                <a
                  href={process.env.GATSBY_URL_PREFIX + `/${quiz.slug}/page/2`}
                  id="start-quiz"
                  className="btn btn-primary start-quiz-button"
                >
                  <div className="start-quiz-button-play">
                    <div style={{ backgroundImage: "url(" + play + ")" }}></div>
                  </div>
                  <span>Start Quiz</span>
                </a>
                <div
                  className="chevron"
                  style={{ backgroundImage: "url(" + chevronLeft + ")", backgroundPosition: "right" }}
                ></div>
              </div>
            </div>

            {quiz.description ? (
              <div className="row">
                <div
                  className="col-md-12 game-intro-copy pad-bottom-15"
                  dangerouslySetInnerHTML={{ __html: quiz.description }}
                />
              </div>
            ) : null}

            {quiz.description && quiz.description.length > 600 ? (
              <div className="row pad-bottom-30">
                <div className="col-md-12 start-quiz-button-wrap">
                  <div
                    className="chevron"
                    style={{ backgroundImage: "url(" + chevronRight + ")", backgroundPosition: "left" }}
                  ></div>
                  <a
                    href={process.env.GATSBY_URL_PREFIX + `/${quiz.slug}/page/2`}
                    id="start-quiz"
                    className="btn btn-primary start-quiz-button"
                  >
                    <div className="start-quiz-button-play">
                      <div style={{ backgroundImage: "url(" + play + ")" }}></div>
                    </div>
                    <span>Start Quiz</span>
                  </a>
                  <div
                    className="chevron"
                    style={{ backgroundImage: "url(" + chevronLeft + ")", backgroundPosition: "right" }}
                  ></div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  private get nextQuestionImage() {
    return this.props.data.nextQuestionImage ? this.props.data.nextQuestionImage.childImageSharp.fluid : null
  }
}

export const query = graphql`
  query QuizIntroQuery($slug: String!, $featuredImage: String, $nextQuestionImage: String) {
    featuredImage: file(relativePath: { eq: $featuredImage }) {
      relativePath
      name
      childImageSharp {
        fluid {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }

    quizzesJson(node: { slug: { eq: $slug } }) {
      node {
        id
        title
        shortTitle
        description
        author {
          firstName
          lastName
        }
        slug
        publishAt
      }
    }
    nextQuestionImage: file(relativePath: { eq: $nextQuestionImage }) {
      relativePath
      name
      childImageSharp {
        fluid(maxHeight: 400) {
          base64
          aspectRatio
          src
          srcSet
          sizes
          presentationWidth
          presentationHeight
        }
      }
    }
  }
`
